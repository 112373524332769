<template>
  <div ref="permissionDeniedPage" aria-labelledby="permission denied page">
    <div class="text-3xl mb-5" data-cy="header-permissions">
      You do not have permissions to view this page.
    </div>
    <div class="flex justify-start">
      <div class="">
        <router-link
          to="/"
          class="text-white p-2 rounded bg-purple hover:bg-dark-purple"
        >
          Go back to the home page
        </router-link>
      </div>
      <div class="mx-2">
        <router-link
          to="/login"
          class="text-white p-2 rounded bg-purple hover:bg-dark-purple"
        >
          Try logging in again
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PermissionDenied",
  mounted() {
    this.$refs.permissionDeniedPage.focus();
  }
};
</script>
